exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-case-studies-js": () => import("./../../../src/pages/case-studies.js" /* webpackChunkName: "component---src-pages-case-studies-js" */),
  "component---src-pages-company-global-offices-js": () => import("./../../../src/pages/company/global-offices.js" /* webpackChunkName: "component---src-pages-company-global-offices-js" */),
  "component---src-pages-company-open-positions-js": () => import("./../../../src/pages/company/open-positions.js" /* webpackChunkName: "component---src-pages-company-open-positions-js" */),
  "component---src-pages-company-our-story-js": () => import("./../../../src/pages/company/our-story.js" /* webpackChunkName: "component---src-pages-company-our-story-js" */),
  "component---src-pages-company-our-vision-js": () => import("./../../../src/pages/company/our-vision.js" /* webpackChunkName: "component---src-pages-company-our-vision-js" */),
  "component---src-pages-company-vection-technologies-js": () => import("./../../../src/pages/company/vection-technologies.js" /* webpackChunkName: "component---src-pages-company-vection-technologies-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-integration-js": () => import("./../../../src/pages/integration.js" /* webpackChunkName: "component---src-pages-integration-js" */),
  "component---src-pages-investor-center-adr-program-js": () => import("./../../../src/pages/investor-center/adr-program.js" /* webpackChunkName: "component---src-pages-investor-center-adr-program-js" */),
  "component---src-pages-investor-center-analyst-research-js": () => import("./../../../src/pages/investor-center/analyst-research.js" /* webpackChunkName: "component---src-pages-investor-center-analyst-research-js" */),
  "component---src-pages-investor-center-compliance-rules-js": () => import("./../../../src/pages/investor-center/compliance-rules.js" /* webpackChunkName: "component---src-pages-investor-center-compliance-rules-js" */),
  "component---src-pages-investor-center-faq-js": () => import("./../../../src/pages/investor-center/faq.js" /* webpackChunkName: "component---src-pages-investor-center-faq-js" */),
  "component---src-pages-investor-center-governance-js": () => import("./../../../src/pages/investor-center/governance.js" /* webpackChunkName: "component---src-pages-investor-center-governance-js" */),
  "component---src-pages-investor-center-investor-presentation-js": () => import("./../../../src/pages/investor-center/investor-presentation.js" /* webpackChunkName: "component---src-pages-investor-center-investor-presentation-js" */),
  "component---src-pages-investor-center-leadership-js": () => import("./../../../src/pages/investor-center/leadership.js" /* webpackChunkName: "component---src-pages-investor-center-leadership-js" */),
  "component---src-pages-investor-center-market-announcement-js": () => import("./../../../src/pages/investor-center/market-announcement.js" /* webpackChunkName: "component---src-pages-investor-center-market-announcement-js" */),
  "component---src-pages-investor-center-media-js": () => import("./../../../src/pages/investor-center/media.js" /* webpackChunkName: "component---src-pages-investor-center-media-js" */),
  "component---src-pages-investor-center-overview-js": () => import("./../../../src/pages/investor-center/overview.js" /* webpackChunkName: "component---src-pages-investor-center-overview-js" */),
  "component---src-pages-investor-center-stock-price-js": () => import("./../../../src/pages/investor-center/stock-price.js" /* webpackChunkName: "component---src-pages-investor-center-stock-price-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-solutions-industries-aerospace-js": () => import("./../../../src/pages/solutions/industries/aerospace.js" /* webpackChunkName: "component---src-pages-solutions-industries-aerospace-js" */),
  "component---src-pages-solutions-industries-architecture-engineering-and-construction-js": () => import("./../../../src/pages/solutions/industries/architecture-engineering-and-construction.js" /* webpackChunkName: "component---src-pages-solutions-industries-architecture-engineering-and-construction-js" */),
  "component---src-pages-solutions-industries-automotive-js": () => import("./../../../src/pages/solutions/industries/automotive.js" /* webpackChunkName: "component---src-pages-solutions-industries-automotive-js" */),
  "component---src-pages-solutions-industries-betting-js": () => import("./../../../src/pages/solutions/industries/betting.js" /* webpackChunkName: "component---src-pages-solutions-industries-betting-js" */),
  "component---src-pages-solutions-industries-defence-military-and-law-enforcement-js": () => import("./../../../src/pages/solutions/industries/defence-military-and-law-enforcement.js" /* webpackChunkName: "component---src-pages-solutions-industries-defence-military-and-law-enforcement-js" */),
  "component---src-pages-solutions-industries-education-js": () => import("./../../../src/pages/solutions/industries/education.js" /* webpackChunkName: "component---src-pages-solutions-industries-education-js" */),
  "component---src-pages-solutions-industries-fashion-js": () => import("./../../../src/pages/solutions/industries/fashion.js" /* webpackChunkName: "component---src-pages-solutions-industries-fashion-js" */),
  "component---src-pages-solutions-industries-furniture-and-home-decor-js": () => import("./../../../src/pages/solutions/industries/furniture-and-home-decor.js" /* webpackChunkName: "component---src-pages-solutions-industries-furniture-and-home-decor-js" */),
  "component---src-pages-solutions-industries-healthcare-and-pharma-js": () => import("./../../../src/pages/solutions/industries/healthcare-and-pharma.js" /* webpackChunkName: "component---src-pages-solutions-industries-healthcare-and-pharma-js" */),
  "component---src-pages-solutions-industries-machinery-manufacturing-js": () => import("./../../../src/pages/solutions/industries/machinery-manufacturing.js" /* webpackChunkName: "component---src-pages-solutions-industries-machinery-manufacturing-js" */),
  "component---src-pages-solutions-industries-media-and-communications-js": () => import("./../../../src/pages/solutions/industries/media-and-communications.js" /* webpackChunkName: "component---src-pages-solutions-industries-media-and-communications-js" */),
  "component---src-pages-solutions-industries-naval-and-shipbuilding-js": () => import("./../../../src/pages/solutions/industries/naval-and-shipbuilding.js" /* webpackChunkName: "component---src-pages-solutions-industries-naval-and-shipbuilding-js" */),
  "component---src-pages-solutions-industries-overview-js": () => import("./../../../src/pages/solutions/industries/overview.js" /* webpackChunkName: "component---src-pages-solutions-industries-overview-js" */),
  "component---src-pages-solutions-industries-public-sector-js": () => import("./../../../src/pages/solutions/industries/public-sector.js" /* webpackChunkName: "component---src-pages-solutions-industries-public-sector-js" */),
  "component---src-pages-solutions-industries-retail-js": () => import("./../../../src/pages/solutions/industries/retail.js" /* webpackChunkName: "component---src-pages-solutions-industries-retail-js" */),
  "component---src-pages-solutions-industries-sports-js": () => import("./../../../src/pages/solutions/industries/sports.js" /* webpackChunkName: "component---src-pages-solutions-industries-sports-js" */),
  "component---src-pages-solutions-js": () => import("./../../../src/pages/solutions.js" /* webpackChunkName: "component---src-pages-solutions-js" */),
  "component---src-pages-solutions-need-overview-js": () => import("./../../../src/pages/solutions-need-overview.js" /* webpackChunkName: "component---src-pages-solutions-need-overview-js" */),
  "component---src-pages-solutions-products-3-d-frame-overview-js": () => import("./../../../src/pages/solutions/products/3d-frame/overview.js" /* webpackChunkName: "component---src-pages-solutions-products-3-d-frame-overview-js" */),
  "component---src-pages-solutions-products-3-d-frame-webex-js": () => import("./../../../src/pages/solutions/products/3d-frame/webex.js" /* webpackChunkName: "component---src-pages-solutions-products-3-d-frame-webex-js" */),
  "component---src-pages-solutions-products-configurator-get-started-js": () => import("./../../../src/pages/solutions/products/configurator/get-started.js" /* webpackChunkName: "component---src-pages-solutions-products-configurator-get-started-js" */),
  "component---src-pages-solutions-products-configurator-overview-js": () => import("./../../../src/pages/solutions/products/configurator/overview.js" /* webpackChunkName: "component---src-pages-solutions-products-configurator-overview-js" */),
  "component---src-pages-solutions-products-enwork-get-started-js": () => import("./../../../src/pages/solutions/products/enwork/get-started.js" /* webpackChunkName: "component---src-pages-solutions-products-enwork-get-started-js" */),
  "component---src-pages-solutions-products-enwork-overview-js": () => import("./../../../src/pages/solutions/products/enwork/overview.js" /* webpackChunkName: "component---src-pages-solutions-products-enwork-overview-js" */),
  "component---src-pages-solutions-products-mindesk-overview-js": () => import("./../../../src/pages/solutions/products/mindesk/overview.js" /* webpackChunkName: "component---src-pages-solutions-products-mindesk-overview-js" */),
  "component---src-pages-solutions-products-overview-js": () => import("./../../../src/pages/solutions-products-overview.js" /* webpackChunkName: "component---src-pages-solutions-products-overview-js" */),
  "component---src-pages-solutions-products-xrkiosk-get-started-js": () => import("./../../../src/pages/solutions/products/xrkiosk/get-started.js" /* webpackChunkName: "component---src-pages-solutions-products-xrkiosk-get-started-js" */),
  "component---src-pages-solutions-products-xrkiosk-overview-js": () => import("./../../../src/pages/solutions/products/xrkiosk/overview.js" /* webpackChunkName: "component---src-pages-solutions-products-xrkiosk-overview-js" */),
  "component---src-pages-success-stories-js": () => import("./../../../src/pages/success-stories.js" /* webpackChunkName: "component---src-pages-success-stories-js" */),
  "component---src-pages-support-services-3-d-modeling-rendering-js": () => import("./../../../src/pages/support/services/3d-modeling-rendering.js" /* webpackChunkName: "component---src-pages-support-services-3-d-modeling-rendering-js" */),
  "component---src-pages-support-services-ict-infrastructure-js": () => import("./../../../src/pages/support/services/ict-infrastructure.js" /* webpackChunkName: "component---src-pages-support-services-ict-infrastructure-js" */),
  "component---src-pages-support-services-js": () => import("./../../../src/pages/support/services.js" /* webpackChunkName: "component---src-pages-support-services-js" */),
  "component---src-pages-support-services-kiosks-js": () => import("./../../../src/pages/support/services/kiosks.js" /* webpackChunkName: "component---src-pages-support-services-kiosks-js" */),
  "component---src-pages-support-services-metaverse-web-3-0-js": () => import("./../../../src/pages/support/services/metaverse-web3.0.js" /* webpackChunkName: "component---src-pages-support-services-metaverse-web-3-0-js" */),
  "component---src-pages-support-services-xr-customization-white-labels-js": () => import("./../../../src/pages/support/services/xr-customization-white-labels.js" /* webpackChunkName: "component---src-pages-support-services-xr-customization-white-labels-js" */),
  "component---src-pages-support-technology-what-is-augmented-reality-js": () => import("./../../../src/pages/support/technology/What-is-augmented-reality.js" /* webpackChunkName: "component---src-pages-support-technology-what-is-augmented-reality-js" */),
  "component---src-pages-support-technology-what-is-extended-reality-js": () => import("./../../../src/pages/support/technology/What-is-extended-reality.js" /* webpackChunkName: "component---src-pages-support-technology-what-is-extended-reality-js" */),
  "component---src-pages-support-technology-what-is-integratedxr-js": () => import("./../../../src/pages/support/technology/what-is-integratedxr.js" /* webpackChunkName: "component---src-pages-support-technology-what-is-integratedxr-js" */),
  "component---src-pages-support-technology-what-is-mixed-reality-js": () => import("./../../../src/pages/support/technology/What-is-mixed-reality.js" /* webpackChunkName: "component---src-pages-support-technology-what-is-mixed-reality-js" */),
  "component---src-pages-support-technology-what-is-the-metaverse-js": () => import("./../../../src/pages/support/technology/What-is-the-metaverse.js" /* webpackChunkName: "component---src-pages-support-technology-what-is-the-metaverse-js" */),
  "component---src-pages-support-technology-what-is-virtual-reality-js": () => import("./../../../src/pages/support/technology/what-is-virtual-reality.js" /* webpackChunkName: "component---src-pages-support-technology-what-is-virtual-reality-js" */),
  "component---src-templates-blog-inner-js": () => import("./../../../src/templates/blog-inner.js" /* webpackChunkName: "component---src-templates-blog-inner-js" */),
  "component---src-templates-global-offices-city-layout-js": () => import("./../../../src/templates/global-offices-city-layout.js" /* webpackChunkName: "component---src-templates-global-offices-city-layout-js" */),
  "component---src-templates-solution-need-js": () => import("./../../../src/templates/solution-need.js" /* webpackChunkName: "component---src-templates-solution-need-js" */),
  "component---src-templates-success-stories-inner-js": () => import("./../../../src/templates/success-stories-inner.js" /* webpackChunkName: "component---src-templates-success-stories-inner-js" */)
}

